// npm
import React from 'react'
import { Box, Stack, StackProps, styled, useTheme } from '@mui/material'

interface Props extends StackProps {
  useAlternateColor: boolean
  heading: string
  bullets: Queries.DatoCmsBulletPoint[]
}

const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  li: {
    marginBottom: theme.spacing(1.5),
  },
}))

const IconCard = ({ useAlternateColor, heading, bullets, ...props }: Props) => {
  const theme = useTheme()
  return (
    <Stack
      alignItems="center"
      justifyContent="stretch"
      sx={{
        boxSizing: 'border-box',
        textAlign: 'center',
        background: `linear-gradient(180deg, ${
          useAlternateColor
            ? theme.palette.secondary.main
            : theme.palette.secondaryDarker
        } 0, ${theme.palette.secondary.dark} 100%)`,
      }}
      pt={5}
      px={5}
      pb={12}
      {...props}
    >
      <Box component="h3" typography="h4" mb={4} color="white">
        {heading}
      </Box>
      <StyledList>
        {bullets.map((bullet: Queries.DatoCmsBulletPoint) => {
          return <li key={bullet.originalId}>{bullet.text}</li>
        })}
      </StyledList>
    </Stack>
  )
}

export default React.memo(IconCard)
